import React, { useState, useEffect } from 'react';
import './ContenedorMaterias.css';
import BotonMateria from './BotonMateria';
import imagen_materia from '../Assets/materia.jpg';
import axios from 'axios';
import { getClases } from '../Pages/constants';
import { useData } from '../Pages/DataContext';

const ContenedorMaterias = ({ redireccionB }) => {
  const [materias, setMaterias] = useState([]);
  const { setClaseData } = useData();
  
  // Paginación
  const [paginaActual, setPaginaActual] = useState(1);
  const materiasPorPagina = 9;

  useEffect(() => {
    const fetchMaterias = async () => {
      try {
        const response = await axios.get(getClases);
        setMaterias(response.data);
      } catch (error) {
        console.error('Error al obtener las materias:', error);
      }
    };
    fetchMaterias();
  }, []);

  const editarClase = (val) => {
    setClaseData(val);
    console.log("Valores de clase: ", val);
  };

  // Calcular total de páginas
  const totalPaginas = Math.ceil(materias.length / materiasPorPagina);
  
  // Calcular las materias de la página actual
  const indiceUltimaMateria = paginaActual * materiasPorPagina;
  const indicePrimeraMateria = indiceUltimaMateria - materiasPorPagina;
  const materiasActuales = materias.slice(indicePrimeraMateria, indiceUltimaMateria);

  return (
    <div className="contenedor-materias">
      {materiasActuales.map((materia, index) => (
        <BotonMateria
          key={index}
          imagen={imagen_materia}
          nombre={(index + 1 + indicePrimeraMateria) + " " + materia.nombre_clase}
          descripcion={materia.descripcion}
          salon={materia.matricula_clase}
          profesor={materia.matricula_maestro}
          redireccion={redireccionB}
          onEditarClase={() => editarClase(materia)}
        />
      ))}

      {/* Paginación */}
      <div className="paginacion">
        <button 
          onClick={() => setPaginaActual(paginaActual - 1)}
          disabled={paginaActual === 1}
        >
          Anterior
        </button>

        <span className="pagina-info">
          Página {paginaActual} de {totalPaginas}
        </span>

        <button 
          onClick={() => setPaginaActual(paginaActual + 1)}
          disabled={paginaActual === totalPaginas}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default ContenedorMaterias;
